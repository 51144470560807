<template>
  <div id="apply-history">
    <header>
      <img
          class="notice-icon"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice.png"
          alt=""
      />
      <div class="notice-content">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice-content.png"
            alt=""
        />
      </div>
    </header>
    <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
    >
      <div class="apply-history-container">
        <div
            class="apply-history-item"
            v-for="product in applyHistoryList"
            :key="product.productId"
        >
          <div class="apply-history-item-header">
            <img
                :src="product.logo"
                alt=""
            />
            <div class="product-name">{{ product.productName }}</div>
            <div class="label">{{ product.label }}</div>
          </div>
          <div class="apply-history-item-loan-money">
            <span>{{ getProductLoanMoney(product) }}</span>
            <em>最高可借(元)</em>
          </div>
          <div class="apply-history-item-loan-time">
            最长期限{{ getProductLoanTime(product) }}个月，年利率低至{{ product.dayRate || '6.0' }}%起
          </div>
          <div
              class="apply-button"
              @click="toApply(product)"
          >
            已申请
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { get_apply_history_list } from '_network/applyHistory'
import { click_product_item } from '_network/superLoan'

export default {
  data() {
    return {
      // 申请记录列表
      applyHistoryList: [],
      // 分页参数
      paramsList: {
        pageIdx: 1,
        pageSize: 10
      },
      // 分页加载是否已经结束
      finished: false,
      loading: false,
      // 这里是默认的借款额度，针对于大额贷产品
      defaultLoanMoney: 0
    }
  },
  methods: {
    // 获取申请记录列表
    async getApplyHistoryList() {
      try {
        this.loading = true
        const { data = [] } = await get_apply_history_list(this.paramsList)
        this.loading = false
        // 如果获取的列表产品少于10个，则让列表结束触底
        if (data.length !== 10) {
          this.finished = true
        }
        this.applyHistoryList.push(...data)
      } catch (e) {}
    },
    /**
     * @description 列表产品的按钮状态
     * @param productType 产品类型 1h5 2联登 3大额贷 4信贷api-h5 5信贷api-本地
     * @param bigLoanApplyState 大额贷产品申请结果 0失败 1成功
     * @param h5click h5产品是否可以点击 0可以 1否
     * @return 1立即申请 2已申请
     * */
    getProductButtonState({ productType, bigLoanApplyState, h5click }) {
      if (
          // 产品类型为h5 并且 是可以点击的产品
          (productType === 1 && h5click === 0)
          // 产品类型为大额贷 并且 申请成功
          || (productType === 3  && bigLoanApplyState === 1)
      ) {
        return 1
      } else {
        return 0
      }
    },
    // 申请产品
    async toApply(product) {
      if (this.getProductButtonState(product) !== 1) {
        // 这里说明产品不是h5类型或者不是申请成功的大额贷，直接终止即可
        return
      }

      if (product.productType === 1) {
        // 需要先添加1次产品的点击次数
        await click_product_item(product.productId)
        // h5产品直接跳转链接
        location.href = product.url
      } else {
        // 其他均为申请成功的大额贷，将产品存于bigLoanProduct字段然后跳转，由于大额贷页面的名称字段为name，所以这里重新赋值了产品名
        product.name = product.productName
        localStorage.setItem('bigLoanProduct', JSON.stringify(product))
        await this.$router.push('/examining')
      }
    },
    // 分页列表触底
    onLoad() {
      this.paramsList.pageIdx++
      this.getApplyHistoryList()
    },
    // 获取产品借款额度
    getProductLoanMoney(product) {
      if (product.productType === 3) {
        // 大额贷直接取默认额度
        return this.defaultLoanMoney
      } else if (product.moneySection) {
        // 产品的额度区间不为空则返回区间最大值
        return product.moneySection.split('-')[1]
      } else {
        // 其他情况默认返回80000
        return 80000
      }
    },
    // 获取产品贷款期限
    getProductLoanTime(product) {
      if (product.productType === 3) {
        // 大额贷直接取36个月
        return 36
      } else if (product.loanTime) {
        // 产品的额度区间不为空则返回区间最大值
        return product.loanTime[product.loanTime.length - 1]
      } else {
        // 其他情况默认返回12
        return 12
      }
    }
  },
  created() {
    this.getApplyHistoryList()
    document.title = '申请记录'

    // 这里获取的用户信息用于页面的额度展示
    const userInfo = this.$getUserInfo()
    switch (userInfo.loanMoney) {
      case '20万以上':
        this.defaultLoanMoney = 300000
        break
      case '10-20万':
        this.defaultLoanMoney = 200000
        break
      case '5-10万':
        this.defaultLoanMoney = 100000
        break
      case '1-5万':
        this.defaultLoanMoney = 50000
        break
      case '2000元-1万':
        this.defaultLoanMoney = 10000
        break
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes move {
  0% {
    transform: translateX(8.4324rem);
  }

  100% {
    transform: translateX(-11.1622rem);
  }
}

#apply-history {
  min-height: 100vh;
  padding-bottom: 0.533rem;
  background: #F8F8F8;

  header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.8649rem;
    padding-left: 17px;
    background: #FBF6E8;

    .notice-icon {
      width: 0.3784rem;
      margin-right: 0.1351rem;
    }

    .notice-content {
      overflow: hidden;
      width: 8.4324rem;
      height: 0.3784rem;

      img {
        width: 11.1622rem;
        transform: translateX(8.4324rem);
        animation: move 16s linear infinite;
      }
    }
  }

  .apply-history-container {
    padding: 0 0.213rem;

    .apply-history-item {
      position: relative;
      margin-top: 0.267rem;
      padding: 0.373rem;
      background: #FFF;
      border-radius: 0.213rem;

      .apply-history-item-header {
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        color: #2C75F9;

        img {
          width: 0.533rem;
          border-radius: 0.107rem;
        }

        .product-name {
          margin: 0 0.213rem 0 0.16rem;
          font-weight: 500;
          font-size: 0.4rem;
          color: #333333;
        }

        .label {
          padding: 0.08rem;
          background: #ECF3FF;
          border-radius: 0.053rem;
        }
      }

      .apply-history-item-loan-money {
        display: flex;
        align-items: center;
        margin-top: 0.373rem;
        font-weight: 600;
        font-size: 0.693rem;
        color: #FF4B3E;

        em {
          margin-left: 0.16rem;
          font-weight: 400;
          font-size: 0.32rem;
          color: #999999;
        }
      }

      .apply-history-item-loan-time {
        font-weight: 400;
        font-size: 0.32rem;
        color: #999999;
      }

      .apply-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0.613rem;
        top: 50%;
        width: 1.867rem;
        height: 0.853rem;
        background: #D1D1D1;
        border-radius: 0.427rem;
        transform: translateY(-50%);
        color: #000000;
      }
    }
  }
}
</style>